<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-search"></i> Asset Recon Report</b-card-title>
			<b-card-sub-title>Summary of asset matching between Ayun! assets and other assets inventory list</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-card v-show="!isReconStarted">
					<b-row>
						<b-col md="12" sm="12" class="my-1">
							<b>REPORT DETAILS</b>
						</b-col>
					</b-row>
					<b-row class="mt-2" no-gutters>
						<b-col lg="4" md="10" sm="12" class="mr-4">
							<b-form-group label="Region">
								<v-select name="Region" class="style-chooser" label="text"
									:options="reconParamOptions.regionItems" :reduce="(region) => region.value"
									v-model="reconParams.region" v-validate="'required'">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a region
										</em>
									</template>
								</v-select>
								<span v-show="errors.has('Region')" class="help-block">
									{{ errors.first('Region') }}
								</span>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="10" sm="12" class="mr-4">
							<b-form-group label="Area">
								<v-select name="Area" class="style-chooser" label="text"
									:options="reconParamOptions.areaItems" :reduce="(area) => area.value"
									v-model="reconParams.area" v-validate="'required'">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for an area
										</em>
									</template>
								</v-select>
								<span v-show="errors.has('Area')" class="help-block">
									{{ errors.first('Area') }}
								</span>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="10" sm="12" class="mr-4">
							<b-form-group label="Company">
								<v-select name="Company" class="style-chooser" label="text"
									:options="reconParamOptions.companyItems" :reduce="(company) => company.value"
									v-model="reconParams.company">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a company
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="mt-2" no-gutters>
						<b-col sm="10" md="8" lg="6" class="mr-4">
							<b-form-group label="Asset Recon Form"
								description="Please select a valid csv file for asset recon form format.">
								<b-form-file name="Asset Recon File" v-model="file" ref="fileinput"
									@change="onUploadForm($event)" :state="Boolean(file)" placeholder="Choose a CSV file"
									v-validate="'required'" />
								<span v-show="errors.has('Asset Recon File')" class="help-block">
									{{ errors.first('Asset Recon File') }}
								</span>
							</b-form-group>
							<div>
								Download CSV template
								<a :href="csvTemplateUrl" download="AssetReconImporter.csv">
									here.
								</a>
							</div>
						</b-col>
					</b-row>
					<b-row class="mt-2" no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="onProceed">
								Proceed
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetReconParams">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<b-row class="my-12" v-show="isReconPreprocess && !isReconStarted">
					<b-col sm="12">
						<AssetReconContentPreview :assets="assets" @onStartRecon="onStartRecon" />
					</b-col>
				</b-row>

				<b-row class="my-12" v-show="isReconStarted">
					<b-col sm="12">
						<AssetReconProcessedSummary :assets="assets" :reconParams="reconParams"
							:fileName="assetReconFileName" />
						<AssetReconProcessedData :reconPerCompany="reconPerCompany" :reconParams="reconParams"
							@onBack="onBack" />
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
// Components
import AssetReconContentPreview from './assetRecon/AssetReconContentPreview';
import AssetReconProcessedSummary from './assetRecon/AssetReconProcessedSummary';
import AssetReconProcessedData from './assetRecon/AssetReconProcessedData';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ImportUtil } from '@/utils/importUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// DAO
import assetDAO from '@/database/assets';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');
import _ from 'lodash';


export default {
	name: 'asset-recon-report',
	components: {
		AssetReconContentPreview,
		AssetReconProcessedSummary,
		AssetReconProcessedData,
		Loading,
	},
	data() {
		return {
			defaultReconParams: {
				region: { ...config.dropdownDefaultValue },
				area: { ...config.dropdownDefaultValue },
				company: { ...config.companyDefaultValue },
			},
			reconParams: {
				region: { ...config.dropdownDefaultValue },
				area: { ...config.dropdownDefaultValue },
				company: { ...config.companyDefaultValue },
			},

			file: null,
			jsonData: null,
			assets: [],
			reconPerCompany: [],

			isReconPreprocess: false,
			isReconStarted: false,
			isReconEnded: false,

			reconParamOptions: {
				regionItems: [],
				areaItems: [],
				companyItems: [],
			},

			allRegionsObj: {},
			allAreasObj: {},
			allCompaniesObj: {},
			allAssetTypesObj: {},
			allUsersObj: {},

			csvTemplateUrl: '../../assets/files/AssetReconImporter.csv',
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		assetReconFileName() {
			return this.file && this.file.name ? this.file.name : '-';
		},
		dummyAssetRegex() {
			return /^DIT-[0-9B]{1}[0-9]{5}-[0-9]{3}$|^DNIT-[0-9B]{1}[0-9]{5}-[0-9]{3}$/;
		}
	},
	watch: {
		"reconParams.region": function (newVal) {
			if (this.reconParams.region !== null) {
				let filteredAreasObj = _.filter(this.allAreasObj, o => {
					return o.region === newVal;
				});
				this.reconParamOptions.areaItems = DropDownItemsUtil.retrieveAreas(filteredAreasObj);
			} else {
				this.reconParamOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);
			}

			// reset area
			this.reconParams.c = null;
		},
		"reconParams.area": function (newVal) {
			if (this.reconParams.area !== null) {
				let filteredCompaniesObj = _.filter(this.allCompaniesObj, o => {
					return o.area === newVal;
				});
				this.reconParamOptions.companyItems = DropDownItemsUtil.retrieveCompanies(filteredCompaniesObj);
			} else {
				this.reconParamOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			}

			// reset company
			this.reconParams.company = { ...config.companyDefaultValue };
		}
	},
	mounted() {
		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.allRegionsObj = this.$store.getters.regions;
				this.reconParamOptions.regionItems = DropDownItemsUtil.retrieveRegions(this.allRegionsObj);

				this.allAreasObj = this.$store.getters.areas;
				this.reconParamOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);

				this.allCompaniesObj = this.$store.getters.companies;
				this.reconParamOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				this.allAssetTypesObj = this.$store.getters.assetTypes;
				this.allUsersObj = this.$store.getters.users;

				this.resetReconParams();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;

		}, config.timeout);
	},
	methods: {
		resetReconParams() {
			this.reconParams = { ...this.defaultReconParams };

			this.isReconPreprocess = false;
			this.isReconStarted = false;
			this.file = null;
			this.jsonData = null;
			this.assets = [];
			this.reconPerCompany = [];
		},

		onUploadForm(ev) {
			this.file = ev.target.files[0];

			if (this.validateUploadForm(this.file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = this.file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
						});

						this.jsonData = {
							assets: data
						};
					}
				};
				reader.readAsText(this.file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning(
					'Please select a valid Asset Recon Form to proceed.'
				);
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid Asset Recon Form in CSV format.'
				);
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},

		async onProceed() {
			let isValid = await this.validateReconParams();
			if (!isValid) {
				return;
			}

			let totalAssets = !_.isEmpty(this.jsonData.assets) ? _.size(this.jsonData.assets) : 0;
			if (totalAssets === 0) {
				this.$toaster.error('No assets to recon from the imported file.');
				return;
			} else if (totalAssets > 100000) {
				this.$toaster.error('Only maximum of 100,000 assets is allowed per recon.');
				return;
			}

			// Show Content Preview
			this.isReconPreprocess = true;

			// show loading indicator
			this.isLoading = true;

			this.assets = this.onPreProcessAssets(this.jsonData.assets);

			// hide loading indicator
			this.isLoading = false;
		},
		async validateReconParams() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return isValid;
			}

			if (!this.reconParams.region || this.reconParams.region.value === null) {
				this.$toaster.warning('Invalid region. Please select a region.');
				isValid = false;
			} else if (!this.reconParams.area || this.reconParams.area.value === null) {
				this.$toaster.warning('Invalid area. Please select an area.');
				isValid = false;
			} else if (!this.file) {
				this.$toaster.warning('Invalid file. Please enter a valid XLS or CSV file.');
				isValid = false;
			}
			return isValid;
		},
		onPreProcessAssets() {
			let assets = this.jsonData.assets;

			let assetCodes = _.map(assets, 'assetCode');
			let invalidAssetCodes = ValidationUtil.getInvalidAssets(this.allAssetTypesObj, assetCodes);

			_.forEach(assets, asset => {
				let assetCode = asset.assetCode;
				let branchCode = asset.branchCode;
				let employeeNo = asset.employeeNo;

				asset.isInvalidAssetCode = invalidAssetCodes.includes(assetCode);
				asset.isInvalidBranchCode = !ValidationUtil.isValidBranchCode(branchCode);
				asset.isInvalidEmployeeNo = !ValidationUtil.isValidEmployeeNo(employeeNo);

				asset.status = !asset.isInvalidAssetCode && !asset.isInvalidBranchCode && !asset.isInvalidEmployeeNo ? 'VALID' : 'INVALID';
			});

			return assets;
		},

		onBack() {
			// Hide Asset Recon Processed Summary 
			this.isReconStarted = false;
		},
		async onStartRecon() {
			if (ImportUtil.hasDuplicates(this.jsonData.assets, 'assetCode')) {
				let duplicates = ImportUtil.getDuplicates(this.jsonData.assets, 'assetCode');
				this.$toaster.warning(
					'Duplicates detected! Please address the following entries: ' +
					JSON.stringify(duplicates)
				);
				return;
			}

			// Show Asset Recon Processed Summary 
			this.isReconStarted = true;

			// show loading indicator
			this.isLoading = true;

			// retrieve assets on selected region and area in ayun
			let companies = this.getCompaniesBasedOnParams();
			let companyIds = _.map(companies, 'id');

			let allAssetsObjResult = await assetDAO.getAssetsOnCurrCompanyIds(companyIds);
			let allAssetsObj = allAssetsObjResult[0];

			let assetsPerComp = _.groupBy(allAssetsObj, 'currCompanyId');
			this.onProcessReconAssets(assetsPerComp);

			// hide loading indicator
			this.isLoading = false;
		},
		getCompaniesBasedOnParams() {
			let companies = [];

			let companyParam = this.reconParams.company ? this.reconParams.company : {};
			if (companyParam.id !== null) {
				let companyId = companyParam.id;
				companies.push(this.allCompaniesObj[companyId]);
			} else {
				companies = _.filter(this.allCompaniesObj, o => {
					return o.region === this.reconParams.region && o.area === this.reconParams.area;
				});
			}
			return companies;
		},
		onProcessReconAssets(assetsPerComp) {
			// reset
			this.reconPerCompany = [];

			// get groupings of assets to recon per branchCode
			let ayunAssetsPerBranchCode = this.getAyunAssetsPerBranchCode(assetsPerComp);
			let allReconAssets = _.keyBy(this.assets, 'assetCode');
			let nonAyunAssetsPerBranchCode = this.getNonAyunAssetsPerBranchCode(this.assets);

			let companyObjs = this.getCompaniesBasedOnParams();

			for (let companyObj of companyObjs) {
				let branchCode = companyObj.branchCode;

				let row = {};
				row.companyId = companyObj.id;
				row.company = companyObj.name;
				row.branchCode = branchCode;

				row = this.processDummyAssets(row, ayunAssetsPerBranchCode[branchCode]);

				let ayunAssets = this.getAyunAssets(row, ayunAssetsPerBranchCode[branchCode]);
				let nonAyunAssets = this.getNonAyunAssets(row, nonAyunAssetsPerBranchCode[branchCode]);
				row.totalNonAyunAssets = _.size(nonAyunAssets);

				row = this.processMatchedLocation(row, ayunAssets, nonAyunAssets, branchCode, allReconAssets);
				row = this.processMatchedAccountability(row, ayunAssets, nonAyunAssets, allReconAssets);

				this.reconPerCompany.push(row);
			}
		},
		processDummyAssets(row, ayunAssets) {
			row.dummyAssets = _.filter(ayunAssets, o => {
				return this.dummyAssetRegex.test(o.assetCode);
			});
			row.totalDummyAssets = _.size(row.dummyAssets);

			return row;
		},
		getAyunAssets(row, ayunAssets) {
			let dummyAssetCodes = _.map(row.dummyAssets, 'assetCode');

			row.ayunAssets = _.keyBy(_.filter(ayunAssets, o => {
				return !dummyAssetCodes.includes(o.assetCode);
			}), 'assetCode');

			row.totalAyunAssets = _.size(row.ayunAssets);

			return row.ayunAssets;
		},
		getNonAyunAssets(row, nonAyunAssets) {
			row.nonAyunAssets = _.keyBy(nonAyunAssets, 'assetCode');
			row.totalNonAyunAssets = _.size(nonAyunAssets);

			return row.nonAyunAssets;
		},

		getAyunAssetsPerBranchCode(assetsPerComp) {
			let ayunAssetsPerBranchCode = {};
			_.forEach(assetsPerComp, (assets, companyId) => {
				let companyObj = this.allCompaniesObj[companyId];
				let branchCode = companyObj.branchCode;
				ayunAssetsPerBranchCode[branchCode] = assets;
			});

			return ayunAssetsPerBranchCode;
		},
		getNonAyunAssetsPerBranchCode(assets) {
			let nonAyunAssetsPerBranchCode = {};

			let validAssets = _.filter(assets, o => {
				return o.status === 'VALID';
			});
			nonAyunAssetsPerBranchCode = _.groupBy(validAssets, 'branchCode');

			return nonAyunAssetsPerBranchCode;
		},

		processMatchedLocation(row, ayunAssets, nonAyunAssets, branchCode, allReconAssets) {
			let ayunAssetCodes = _.map(ayunAssets, 'assetCode');
			let nonAyunAssetCodes = _.map(nonAyunAssets, 'assetCode');
			let matchedLocationAssetCodes = _.intersection(ayunAssetCodes, nonAyunAssetCodes);

			row.ayunAssetCodes = ayunAssetCodes;
			row.nonAyunAssetCodes = nonAyunAssetCodes;
			row.totalMatchedLocation = _.size(matchedLocationAssetCodes);
			row.totalUnmatchedLocation = row.totalAyunAssets - row.totalMatchedLocation;

			let reconAssets = {};
			_.forEach(ayunAssetCodes, assetCode => {
				let reconAsset = {};

				reconAsset.assetCode = assetCode;

				// add description and condition from asset object
				let assetObj = ayunAssets[assetCode];
				let details = assetObj ? assetObj.details : {};
				reconAsset.description = details.description ? details.description : '-';
				reconAsset.easDescription = allReconAssets[assetCode] ? allReconAssets[assetCode].description : '-' ;
				reconAsset.condition = details.condition ? details.condition : '-';

				reconAsset.branchCode = branchCode;
				reconAsset.branchCodeInNonAyun = allReconAssets[assetCode] ? allReconAssets[assetCode].branchCode : '-';
				reconAsset.existsInAyun = ayunAssetCodes.includes(assetCode) ? 'Yes' : 'No';
				reconAsset.existsInNonAyun = nonAyunAssetCodes.includes(assetCode) ? 'Yes' : 'No';
				reconAsset.matchedLocation = matchedLocationAssetCodes.includes(assetCode) ? 'Matched' : 'Unmatched';

				reconAssets[assetCode] = reconAsset;
			});
			row.reconAssets = reconAssets;

			return row;
		},
		processMatchedAccountability(row, ayunAssets, nonAyunAssets, allReconAssets) {
			let ayunAssetsObj = _.keyBy(ayunAssets, 'assetCode');
			let ayunAssetCodes = row.ayunAssetCodes;

			_.forEach(ayunAssetCodes, assetCode => {
				let reconAsset = row.reconAssets[assetCode];

				if (reconAsset.matchedLocation) {
					let userInAyun = this.getUserInAyun(ayunAssetsObj, assetCode);
					reconAsset.userInAyun = userInAyun;

					let userInNonAyun = this.getUserInNonAyun(allReconAssets, assetCode);
					reconAsset.userInNonAyun = userInNonAyun;

					reconAsset.matchedAccountability = this.hasMatchedAccountability(userInAyun, userInNonAyun);
				} else {
					reconAsset.userInAyun = '-';
					reconAsset.userInNonAyun = '-';
					reconAsset.matchedAccountability = 'N/A';
				}
			});

			row.totalMatchedAccountability = _.size(_.filter(row.reconAssets, o => {
				return o.matchedAccountability === 'Matched' && o.matchedLocation === 'Matched';
			}));
			row.totalUnmatchedAccountability = _.size(_.filter(row.reconAssets, o => {
				return o.matchedAccountability === 'Unmatched' && o.matchedLocation === 'Matched';
			}));

			return row;
		},
		getUserInAyun(ayunAssetsObj, assetCode) {
			let ayunAssetObj = ayunAssetsObj[assetCode];
			let ayunUserId = ayunAssetObj.details.inventoriedBy;
			let ayunUserObj = this.allUsersObj[ayunUserId] ? this.allUsersObj[ayunUserId] : {};

			if (!_.isEmpty(ayunUserObj)) {
				return ayunUserObj.employeeNo ? ayunUserObj.employeeNo : '-';
			}
			return '-';
		},
		getUserInNonAyun(nonAyunAssetsObj, assetCode) {
			let nonAyunAssetObj = nonAyunAssetsObj[assetCode];
			if (!_.isEmpty(nonAyunAssetObj)) {
				return nonAyunAssetObj.employeeNo ? nonAyunAssetObj.employeeNo : '-';
			}
			return '-';
		},
		hasMatchedAccountability(userInAyun, userInNonAyun) {
			if (userInAyun === '-' && userInNonAyun === '-') {
				return 'N/A';
			} else {
				return userInAyun === userInNonAyun ? 'Matched' : 'Unmatched';
			}
		}
	},
};
</script>

<style scoped></style>
